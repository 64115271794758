import { CheckDisable, TableProps } from './Table.types';

const TableController = ({
    data,
    handleInvoiceList,
    handleInvoiceListAll,
    setExportAllState,
    invoiceTokenList,
    checkBoxList,
    setCheckBoxList,
    setCheckAll
}: TableProps) => {
    const handlerChangeCheckBoxList = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckBoxList({ ...checkBoxList, [event.target.id]: event.target.checked });
        handleInvoiceList(event.target.id);
        if (event.target.checked === false && invoiceTokenList.length < 1) {
            setExportAllState(false);
        }
    };

    const handlerChangeCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckAll(event.target.checked);
        let newDataCheck: string[] = [];
        let newData = {};

        if (event.target.checked) {
            data.map((element) => {
                newData = { ...newData, [element.token]: true };
                if (CheckDisable[element.status]) {
                    newDataCheck.push(element.token);
                }
            });
            handleInvoiceListAll(newDataCheck);
            setCheckBoxList(newData);
        } else {
            handleInvoiceListAll([]);
            data.map((element) => {
                newData = { ...newData, [element.token]: false };
            });

            setCheckBoxList(newData);
        }
    };
    return { handlerChangeCheckAll, handlerChangeCheckBoxList };
};
export default TableController;
