import { RootState } from '@/store/rootReducer';
import React from 'react';
import { useSelector } from 'react-redux';
import AppLayout from '../AppLayout/AppLayout';
import GuestUserLayout from '../GuestUserLayout/GuestUserLayout';

interface LayoutProps {
    children?: React.ReactNode;
}
const Layout = ({ children }: LayoutProps) => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.login.isAuthenticated);

    if (isAuthenticated) {
        return <AppLayout>{children}</AppLayout>;
    }
    return <GuestUserLayout>{children}</GuestUserLayout>;
};

export default Layout;
