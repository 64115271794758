import classNames from 'classnames';
import InputPasswordController from './InputPassword.controller';
import { InputPasswordProps } from './InputPassword.types';
import styles from './InputPassword.module.scss';

import stylesForm from './../../forms.module.scss';

function InputPassword(props: InputPasswordProps) {
    const { error, visible, toogleVisible, handleOnChange } = InputPasswordController(
        props.onChange,
        props.fieldValidators
    );

    return (
        <div className="input-group has-validation">
            <input
                className={classNames(
                    'form-control',
                    {
                        'is-invalid': error,
                        'is-valid': !error && props.value.length > 0
                    },
                    props.companyName ? stylesForm[`input_${props.companyName}`] : stylesForm.input
                )}
                placeholder={props.placeholder}
                type={visible ? 'text' : 'password'}
                name={props.name}
                autoComplete="current-password"
                aria-describedby={`${props.name}-visibility-button`}
                id={props.name}
                value={props.value}
                onChange={handleOnChange}
                disabled={props.disabled}
                required={props.required}
            />
            <button
                className={props.companyName ? styles[`button_${props.companyName}`] : styles.input}
                id={`${props.name}-visibility-button`}
                type="button"
                onClick={toogleVisible}
            >
                <i
                    className={classNames('bi', {
                        'bi-eye-slash-fill': visible,
                        'bi-eye-fill': !visible
                    })}
                ></i>
            </button>
            <div className="invalid-feedback">{error || props.defaultValidationMessage}</div>
        </div>
    );
}

export default InputPassword;
