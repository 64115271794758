import { FieldValidator } from '@/components/forms/fields/Fields.types';
import { RootState } from '@/store/rootReducer';
import { signUp } from '@/store/auth/signUp.slice';
import Toast from 'bootstrap/js/dist/toast';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function FormController() {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [cif, setCif] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [valid, setValid] = useState<boolean>(true);

    const dispatch = useDispatch();

    const loading = useSelector((state: RootState) => state.auth.signUp.isLoading);
    const error = useSelector((state: RootState) => state.auth.signUp.error);
    const registered = useSelector((state: RootState) => state.auth.signUp.registered);

    const toastRef = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (toastRef.current && error) {
            const toast = new Toast(toastRef.current);

            toast.show();

            setName('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setPhone('');
            setCif('');

            if (formRef.current) {
                formRef.current.reset();
            }
        }
    }, [error]);

    const emailValidators: FieldValidator<string>[] = [
        {
            callback: (value: string) => value.length > 0,
            message: 'El campo no puede estar vacío'
        },
        {
            callback: (value: string) =>
                value.match(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) !== null,
            message: 'El email no tienes un formato válido'
        }
    ];

    const nameValidators: FieldValidator<string>[] = [
        {
            callback: (value: string) => value.length > 0,
            message: 'El campo no puede estar vacío'
        }
    ];

    const cifValidators: FieldValidator<string>[] = [
        {
            callback: (value: string) => value.length > 0,
            message: 'El campo no puede estar vacío'
        },
        {
            callback: (value: string) => value.match(/(^[0-9]{8}[A-Z]$)|(^[A-Z]{1}[0-9]{8}$)|(^[XYZ][0-9]{7}[A-Z]$)/) !== null,
            message: 'El CIF/NIF/NIE no tiene un formato válido'
        }
    ];

    const phoneValidators: FieldValidator<string>[] = [
        {
            callback: (value: string) => value.length > 0,
            message: 'El campo no puede estar vacío'
        },
        {
            callback: (value: string) => value.match(/^[0-9]{9}$/) !== null,
            message: 'El teléfono no tiene un formato válido'
        }
    ];

    const passwordValidators: FieldValidator<string>[] = [
        {
            callback: (value: string) => value.length > 0,
            message: 'El campo no puede estar vacío'
        },
        {
            callback: (value: string) => value.length >= 8,
            message: 'La contraseña debe tener al menos 8 caracteres'
        }
    ];

    const confirmPasswordValidators: FieldValidator<string>[] = [
        {
            callback: (value: string) => value.length > 0,
            message: 'El campo no puede estar vacío'
        },
        {
            callback: (value: string) => value === password,
            message: 'Las contraseñas no coinciden'
        }
    ];

    const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(true);

        setEmail(event.target.value);
    };

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(true);

        setName(event.target.value);
    };

    const onChangeCif = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(true);

        setCif(event.target.value);
    };

    const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(true);

        setPhone(event.target.value);
    };

    const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(true);

        setPassword(event.target.value);
    };

    const onChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(true);

        setConfirmPassword(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (formRef.current) {
            if (!formRef.current.checkValidity()) {
                event.stopPropagation();

                setValid(false);
            } else {
                dispatch(signUp({ name, email, password, cif, phone }));
            }
        }
    };

    return {
        name,
        email,
        cif,
        phone,
        password,
        confirmPassword,
        loading,
        error,
        registered,
        toastRef,
        emailValidators,
        nameValidators,
        cifValidators,
        phoneValidators,
        passwordValidators,
        confirmPasswordValidators,
        onChangeEmail,
        onChangeName,
        onChangeCif,
        onChangePhone,
        onChangePassword,
        onChangeConfirmPassword,
        handleSubmit,
        valid,
        formRef
    };
}

export default FormController;
