import request from '@/utils/helpers/apiResponse.helper';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Confirmation.module.scss';
const Confirmation = () => {
    const params = useParams();
    const [text, setText] = useState('Cargando...');
    useEffect(() => {
        request({
            url: `/user/confirmation/${params.token}`,
            method: 'GET'
        })
            .then((res) => {
                setText(
                    '¡Ya estás verificado! Pronto validaremos tu cuenta y podrás empezar a usar la plataforma'
                );
            })
            .catch((error) => {
                setText('Ups, algo ha ido mal');
            });
    }, []);
    return <div className={styles.container}>{text}</div>;
};

export default Confirmation;
