import RowOptionsController, { RowOptionsProps } from './RowOptions.controller';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip';

const invoiceStatusOptions: any = {
    downloaded: { download: true, export: true, correction: true, validate: true, delete: true },
    processed: { download: true, export: true, correction: true, validate: true, delete: false },
    processing: { download: false, export: false, correction: false, validate: false, delete: false },
    validated: { download: true, export: true, correction: false, validate: false, delete: false }, // no va a haber ninguna así
    error: { download: false, export: false, correction: false, validate: false, delete: true },
    cleared: { download: false, export: false, correction: false, validate: false, delete: true } // no va a haber ninguna así
};
const RowOptions = ({ invoiceName, invoiceStatus, tokenInvoice }: RowOptionsProps) => {
    const { handlerDownloadImgPdf, handlerExport, handlerCorrection, handlerValidate, handlerDelete } =
        RowOptionsController({ invoiceName, invoiceStatus, tokenInvoice });

    const invoiceStatusOp = invoiceStatusOptions[invoiceStatus];

    return (
        <div className="btn-group" role="group" aria-label="Basic mixed styles example">
            <button
                type="button"
                className="btn btn-primary"
                disabled={!invoiceStatusOp.download}
                onClick={handlerDownloadImgPdf}
            >
                <i className="bi bi-cloud-download me-2"></i>
                Descargar
            </button>
            <button
                type="button"
                className="btn btn-primary"
                disabled={!invoiceStatusOp.export}
                onClick={handlerExport}
            >
                <i className="bi bi-file-earmark-arrow-down me-2"></i>
                Exportar
            </button>
            <button
                type="button"
                className="btn btn-primary"
                disabled={!invoiceStatusOp.correction}
                onClick={handlerCorrection}
            >
                <i className="bi bi-tools me-2"></i>
                Corregir
            </button>
            <button
                type="button"
                className="btn btn-primary"
                disabled={!invoiceStatusOp.validate}
                onClick={handlerValidate}
            >
                <i className="bi bi-check-lg me-2"></i>
                Validar
            </button>
            <div id={"delete-button-div-" + tokenInvoice} data-tip-disable={false}>
                <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!invoiceStatusOp.delete}
                    onClick={handlerDelete}
                >
                    <i className="bi bi-trash me-2" />
                    Eliminar
                </button>
            </div>
            {!invoiceStatusOp.delete && invoiceStatus == "processed" &&
                <Tooltip
                    anchorId={"delete-button-div-" + tokenInvoice}
                    variant="info"
                    content="Para borrar el documento primero debes ver el resultado"
                />
            }
        </div>
    );
};

export default RowOptions;
