import classNames from 'classnames';

import InputTextController from './InputText.controller';
import { InputTextProps } from './InputText.types';
import styles from './../../forms.module.scss';

function InputText(props: InputTextProps) {
    const { error, handleOnChange } = InputTextController(props.onChange, props.fieldValidators);

    return (
        <div className="input-group has-validation">
            <input
                className={classNames(
                    'form-control',
                    {
                        'is-invalid': error,
                        'is-valid': !error && props.value.length > 0
                    },
                    styles.input
                )}
                placeholder={props.placeholder}
                type="text"
                name={props.name}
                autoComplete={props.autoComplete}
                id={props.name}
                value={props.value}
                onChange={handleOnChange}
                disabled={props.disabled}
                required={props.required}
                autoFocus={props.autoFocus}
            />
            <div className="invalid-feedback">{error || props.defaultValidationMessage}</div>
        </div>
    );
}

export default InputText;
