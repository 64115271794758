import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Error } from '@/models/Error';
import accessToken from '@/utils/helpers/accessToken.helper';

interface Quota {
    api_use: number;
    quota: number;
}

export const getQuota = createAsyncThunk<Quota, void, { rejectValue: Error }>(
    'users/getQuota',
    async (params, thunkApi) => {
        return request({
            url: '/client/quota',
            method: 'GET',
            extraHeaders: {
                Authorization: `Bearer ${accessToken.get()}`
            }
        })
            .then((res: ApiResponse<Quota>) => res.data)
            .catch((err: ApiResponse<Error>) => {
                return thunkApi.rejectWithValue(err.data);
            });
    }
);

type GetQuotaState = {
    loading: boolean;
    error: Error | null;
    api_use: number | null;
    quota: number | null;
};

export const getQuotaSlice = createSlice({
    name: 'quota',
    initialState: {
        api_use: null,
        quota: null,
        loading: false,
        error: null
    } as GetQuotaState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getQuota.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.api_use = payload.api_use;
            state.quota = payload.quota;
            state.error = null;
        });
        builder.addCase(getQuota.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getQuota.rejected, (state, { payload }) => {
            state.loading = false;
            state.api_use = null;
            state.quota = null;

            if (payload) {
                state.error = payload;
            }
        });
    }
});
