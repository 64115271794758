import { BrowserRouter, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import Login from '@/pages/Login/Login';
import SignUp from '@/pages/SignUp/SignUp';
import Dashboard from '@/pages/Dashboard/Dashboard';
import PrivateRoute from '@/components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Layout from '@/layout/Layout/Layout';
import Confirmation from './pages/Confirmation/Confirmation';

function App() {
    return (
        <>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route
                            path="/login"
                            element={
                                <PublicRoute>
                                    <Login />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path="/sign-up"
                            element={
                                <PublicRoute>
                                    <SignUp />
                                </PublicRoute>
                            }
                        />

                        <Route
                            path="/user/confirmation/:token"
                            element={
                                <PublicRoute>
                                    <Confirmation />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path="/*"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </Layout>
            </BrowserRouter>
            <ToastContainer
                position="top-right"
                limit={3}
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}

export default App;
