import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Error } from '@/models/Error';

export const signUp = createAsyncThunk<
    void,
    {
        name: string;
        email: string;
        password: string;
        cif: string;
        phone?: string;
    },
    { rejectValue: Error }
>('auth/signUp', async (params, thunkApi) => {
    return request({
        url: '/register',
        method: 'POST',
        data: params
    })
        .then((res: ApiResponse<void>) => res.data)
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

type SignUpState = {
    isLoading: boolean;
    error: Error | null;
    registered: boolean;
};

export const authSignUpSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoading: false,
        error: null,
        registered: false
    } as SignUpState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(signUp.fulfilled, (state) => {
            state.isLoading = false;
            state.error = null;
            state.registered = true;
        });
        builder.addCase(signUp.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(signUp.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.registered = false;

            if (payload) {
                state.error = payload;
            }
        });
    }
});
