import { combineReducers } from 'redux';
import { getInvoicesListSlice } from './getInvoicesList.slice';
import { postInvoicesListSlice } from './postInvoicesList.slice';

export const invoicesListReducer = combineReducers({
    invoicesList: getInvoicesListSlice.reducer,
    postInvoicesList: postInvoicesListSlice.reducer
});

export const invoicesListActions = {
    ...getInvoicesListSlice.actions,
    ...postInvoicesListSlice.actions
};
