import { combineReducers } from 'redux';
import { authReducer } from '@/store/auth';
import accessToken from '../utils/helpers/accessToken.helper';
import { usersReducer } from './users';
import { invoicesListReducer } from './invoices';
import { loaderReducer } from './loader';

const authMiddleware = (store: any) => (next: any) => (action: any) => {
    if (action.type === 'auth/logout') {
        accessToken.remove();
    }
};

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    invoicesList: invoicesListReducer,
    loader: loaderReducer
});

export default rootReducer;
