import { combineReducers } from "redux";

import { authLoginSlice } from "./login.slice";
import { authSignUpSlice } from "./signUp.slice";

export const authReducer = combineReducers({
    login: authLoginSlice.reducer,
    signUp: authSignUpSlice.reducer,
});

export const authActions = {
    ...authLoginSlice.actions,
    ...authSignUpSlice.actions,
};