import { InputSubmitProps } from './InputSubmit.types';
import styles from './InputSubmit.module.scss';

export const enum InputSubmitType {
    REGISTER = 'Register',
    LOGIN = 'Login'
}
function InputSubmit(props: InputSubmitProps) {
    return (
        <>
            <div className={styles[`buttonContainer${props.type}`]}>
                <button
                    type="submit"
                    value={props.value}
                    className={props.companyName ? styles[`buttonSubmit_${props.companyName}`] : styles.buttonSubmit}
                    disabled={props.loading || props.disabled}
                />
            </div>
        </>
    );
}

export default InputSubmit;
