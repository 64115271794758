import React, { useEffect, useRef, useState } from 'react';

import { FieldValidator } from '@/components/forms/fields/Fields.types';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '@/store/auth/login.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '@/store/rootReducer';
import { toast } from 'react-toastify';
import biyectivaLogo from './../../assets/images/logoci.png';
import docunectaLogo from './../../assets/images/docunecta-logo.png';
import byproxLogo from './../../assets/images/byprox-logo.png';


export interface CompanyInfo {
    [key: string]: string;
}

interface CompanyLogos {
    [key: string]: React.ReactNode;
}

export const companyNames: CompanyInfo = {
    "biyectiva": "Biyectiva",
    "docunecta": "Docunecta",
    "byprox": "Byprox"
}

export const companyMails: CompanyInfo = {
    "biyectiva": "info@biyectiva.com",
    "docunecta": "docunecta@docunecta.com",
    "byprox": "info@byprox.com"
}

const companyLogos: CompanyLogos = {
    "biyectiva": biyectivaLogo,
    "docunecta": docunectaLogo,
    "byprox": byproxLogo
}


function LoginController() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [valid, setValid] = useState(true);

    const subdomain = window.location.host.split('.')[0];

    const companyName: string = companyNames[subdomain] ?? companyNames["biyectiva"];
    const companyLogo: React.ReactNode = companyLogos[subdomain] ?? biyectivaLogo;

    const { state }: any = useLocation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const loading = useSelector((state: RootState) => state.auth.login.isLoading);
    const error = useSelector((state: RootState) => state.auth.login.error);
    const isAuthenticated = useSelector((state: RootState) => state.auth.login.isAuthenticated);

    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (isAuthenticated) {
            navigate(state?.path || '/');
            if (companyName === 'Biyectiva') {
                toast.success('Bienvenido!! \n la versión demo solo puede procesar datos de tu CIF');
            }
        }
    }, [isAuthenticated]);

    useEffect(() => {
        setEmail('');
        setPassword('');
    }, [error]);

    const emailValidators: FieldValidator<string>[] = [
        {
            callback: (value: string) => value.length > 0,
            message: 'Introduzca su correo'
        },
        {
            callback: (value: string) =>
                value.match(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) !== null,
            message: 'Correo inválido'
        }
    ];
    const passwordValidators: FieldValidator<string>[] = [
        {
            callback: (value: string) => value.length > 0,
            message: 'Introduzca su contraseña'
        },
        {
            callback: (value: string) => value.length >= 8,
            message: 'La contraseña debe tener al menos 8 caracteres'
        }
    ];

    const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(true);

        setEmail(event.target.value);
    };

    const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(true);

        setPassword(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (formRef.current) {
            if (!formRef.current.checkValidity()) {
                event.stopPropagation();

                setValid(false);
            } else {
                dispatch(login({ email, password }));
            }
        }
    };

    return {
        formRef,
        valid,
        email,
        password,
        loading,
        companyLogo,
        companyName,
        emailValidators,
        passwordValidators,
        onChangeEmail,
        onChangePassword,
        handleSubmit
    };
}

export default LoginController;
