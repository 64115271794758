import classNames from 'classnames';
import InputEmailController from './InputEmail.controller';
import { InputEmailProps } from './InputEmail.types';
import styles from './../../forms.module.scss';

function InputEmail(props: InputEmailProps) {
    const { error, handleOnChange } = InputEmailController(props.onChange, props.fieldValidators);

    return (
        <div className="input-group has-validation">
            <input
                className={classNames(
                    'form-control',
                    {
                        'is-invalid': error,
                        'is-valid': !error && props.value.length > 0
                    },
                    props.companyName ? styles[`input_${props.companyName}`] : styles.input
                )}
                placeholder={props.placeholder}
                type="email"
                name={props.name}
                autoComplete="email"
                id={props.name}
                value={props.value}
                onChange={handleOnChange}
                disabled={props.disabled}
                required={props.required}
            />
            <div className="invalid-feedback">{error || props.defaultValidationMessage}</div>
        </div>
    );
}

export default InputEmail;
