import InputEmail from '@/components/forms/fields/InputEmail';
import InputPassword from '@/components/forms/fields/InputPassword';
import InputPhone from '@/components/forms/fields/InputPhone';
import InputText from '@/components/forms/fields/InputText';
import { InputTextAutoComplete } from '@/components/forms/fields/InputText/InputText.types';
import InputSubmit from '@/components/forms/InputSubmit';
import { InputSubmitType } from '@/components/forms/InputSubmit/InputSubmit';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import FormController from './Form.controller';
import formStyles from './Form.module.scss';

function Form() {
    const {
        valid,
        email,
        name,
        cif,
        phone,
        password,
        confirmPassword,
        loading,
        toastRef,
        error,
        formRef,
        emailValidators,
        nameValidators,
        cifValidators,
        phoneValidators,
        passwordValidators,
        confirmPasswordValidators,
        onChangeEmail,
        onChangeName,
        onChangeCif,
        onChangePhone,
        onChangePassword,
        onChangeConfirmPassword,
        handleSubmit
    } = FormController();

    return (
        <form
            className={classNames(
                'row d-table-cell p-4  rounded-end',
                formStyles.form,
                {
                    'was-validated': !valid
                },
                formStyles.containerForm
            )}
            onSubmit={handleSubmit}
            ref={formRef}
            noValidate
        >
            <div className={formStyles.title}>
                <strong>Crear</strong>
                <span className={formStyles['title__otherColor']}>una cuenta.</span>
            </div>
            <div className="mb-3">
                <InputText
                    name="name"
                    placeholder="Nombre de la empresa"
                    onChange={onChangeName}
                    required
                    value={name}
                    autoComplete={InputTextAutoComplete.NAME}
                    fieldValidators={nameValidators}
                    defaultValidationMessage="Introduzca un nombre válido"
                    autoFocus={true}
                />
            </div>
            <div className="mb-3">
                <InputEmail
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    placeholder="Email"
                    fieldValidators={emailValidators}
                    defaultValidationMessage="Introduzca un email válido"
                    required
                />
            </div>
            <div className="mb-3">
                <InputText
                    name="cif"
                    placeholder="CIF/NIF/NIE"
                    onChange={onChangeCif}
                    required
                    value={cif}
                    autoComplete={InputTextAutoComplete.OFF}
                    fieldValidators={cifValidators}
                    defaultValidationMessage="Introduzca un CIF válido"
                />
            </div>
            <div className="mb-3">
                <InputPhone
                    name="phone"
                    placeholder="Teléfono (opcional)"
                    value={phone}
                    onChange={onChangePhone}
                    fieldValidators={phoneValidators}
                    defaultValidationMessage="Introduzca un teléfono válido"
                />
            </div>
            <div className="mb-3">
                <InputPassword
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    placeholder="Contraseña"
                    fieldValidators={passwordValidators}
                    defaultValidationMessage="Introduzca una contraseña válida"
                    required
                />
            </div>
            <div className="mb-3">
                <InputPassword
                    name="confirm-password"
                    value={confirmPassword}
                    onChange={onChangeConfirmPassword}
                    placeholder="Contraseña (repetir)"
                    fieldValidators={confirmPasswordValidators}
                    defaultValidationMessage="Repita la contraseña"
                    required
                />
            </div>
            <div className="">
                <div className="form-check ">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        name="term-and-conditions"
                        id="term-and-conditions"
                        required
                    />
                    <label className="form-check-label " htmlFor="term-and-conditions">
                        He leído y acepto la
                        <a
                            href="https://www.biyectiva.com/es/privacy-policy"
                            className={formStyles['form--link']}
                            target="_blank"
                        >
                            {'  política de privacidad.'}
                        </a>
                    </label>
                    <div className="invalid-feedback">Debes aceptar los términos y condiciones</div>
                </div>
            </div>

            <InputSubmit value="Registrarse" loading={loading} type={InputSubmitType.REGISTER} />

            <Link
                className={classNames('text-center d-block', formStyles['form--link'])}
                to="/login"
            >
                ¿Ya tienes cuenta?
                <br />
                Inicia sesión pulsando en este enlace.
            </Link>
            <a
                className={classNames('text-center d-block', formStyles['form--link'])}
                href="https://youtu.be/0s1_9OLRyjQ"
                target="_blank"
            >
                Tutorial de uso
            </a>
            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 11 }}>
                {error && (
                    <div
                        id="liveToast"
                        className="toast align-items-center text-white bg-primary border-0 bg-danger text-white"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        ref={toastRef}
                    >
                        <div className="toast-body d-flex align-items-center">
                            <i className="bi bi-exclamation-circle flex-shrink-0 me-2 fs-4"></i>
                            <div>{error.code}</div>
                            <button
                                type="button"
                                className="btn-close btn-close-white me-2 m-auto"
                                data-bs-dismiss="toast"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                )}
            </div>
        </form>
    );
}

export default Form;
