import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import ProgressBar from '../ProgressBar/ProgressBar';
import SidebarController from './Sidebar.controller';

import sidebarStyles from './Sidebar.module.scss';

function Sidebar() {
    const { isOpen, toggleSidebar, handleLogout, user, quotaProgress, quotaProgressString } =
        SidebarController();

    return (
        <nav
            className={classNames(
                'd-flex flex-column flex-shrink-0 text-white bg-dark',
                sidebarStyles.sidebar,
                {
                    'p-3': isOpen,
                    [sidebarStyles.sidebar__close]: !isOpen,
                    [sidebarStyles.sidebar__open]: isOpen
                }
            )}
        >
            {/* Header */}
            <div
                className={classNames('d-flex flex-row align-items-center justify-content-center', {
                    'border-bottom border-secondary': !isOpen
                })}
            >
                {isOpen && (
                    <h4 className="text-white text-decoration-none me-auto mb-0">Dashboard</h4>
                )}
                <button
                    className={classNames(
                        'btn btn-link shadow-none text-decoration-none text-white',
                        {
                            'p-3': !isOpen
                        }
                    )}
                    onClick={toggleSidebar}
                >
                    <i
                        className={classNames('bi fs-3', {
                            'bi-chevron-bar-left': isOpen,
                            'bi-chevron-bar-right': !isOpen
                        })}
                    ></i>
                </button>
            </div>

            {/* Divider */}
            {isOpen && <hr />}

            {/* Content */}
            <ul
                className={classNames('nav nav-pills flex-column mb-auto', {
                    'nav-flush text-center': !isOpen
                })}
            >
                <li className={classNames('nav-item', { 'mb-2': isOpen })}>
                    <NavLink
                        to="/upload-files"
                        className={classNames('nav-link text-white', {
                            'py-3 border-bottom border-secondary rounded-0': !isOpen
                        })}
                    >
                        <i
                            className={classNames('bi bi-file-earmark-arrow-up', {
                                'me-2': isOpen,
                                'fs-4': !isOpen
                            })}
                        ></i>
                        {isOpen && <span>Subir archivos</span>}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        to="/manage"
                        className={classNames('nav-link text-white', {
                            'py-3 border-bottom border-secondary rounded-0': !isOpen
                        })}
                    >
                        <i
                            className={classNames('bi bi-file-earmark-bar-graph', {
                                'me-2': isOpen,
                                'fs-4': !isOpen
                            })}
                        ></i>
                        {isOpen && <span>Administrar</span>}
                    </NavLink>
                </li>
            </ul>

            {/* Fee */}

            <div className="d-flex flex-column justify-content-center">
                {isOpen ? (
                    <>
                        <div className={sidebarStyles.quota}>{quotaProgressString}</div>
                        <ProgressBar value={quotaProgressString} progress={quotaProgress} />
                    </>
                ) : (
                    <span className="badge rounded-pill bg-primary mb-3 ms-2 me-2">
                        {quotaProgress.toFixed()}%
                    </span>
                )}
            </div>

            {/* Divider */}
            {isOpen && <hr />}

            {/* Footer */}
            <div className={classNames('dropup', { 'border-top border-secondary': !isOpen })}>
                <a
                    href="#"
                    className={classNames(
                        'd-flex align-items-center text-white text-decoration-none dropdown-toggle',
                        { 'p-3': !isOpen }
                    )}
                    id="dropdownUser"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <i
                        className={classNames('bi bi-person-circle me-2', {
                            'fs-5': !isOpen,
                            'fs-3': isOpen
                        })}
                    ></i>
                    {isOpen && (
                        <span>
                            <strong>{user?.name}</strong>
                        </span>
                    )}
                </a>
                <ul
                    className="dropdown-menu dropdown-menu-end shadow"
                    aria-labelledby="dropdownUser"
                >
                    <li>
                        <Link className="dropdown-item" to="/profile">
                            <i className="bi bi-person-fill me-2"></i>
                            Perfil
                        </Link>
                    </li>
                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    <li>
                        <button className="dropdown-item" onClick={handleLogout}>
                            <i className="bi bi-box-arrow-left me-2"></i>
                            Cerrar sesión
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Sidebar;
