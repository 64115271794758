import classNames from 'classnames';
import InputPhoneController from './InputPhone.controller';
import { InputPhoneProps } from './InputPhone.types';
import styles from './InputPhone.module.scss';
import stylesForm from './../../forms.module.scss';

function InputPhone(props: InputPhoneProps) {
    const { error, handleOnChange } = InputPhoneController(props.onChange, props.fieldValidators);

    return (
        <div className="input-group has-validation">
            <button
                className={classNames(styles.button, 'dropdown-toggle')}
                type="button"
                data-bs-toggle="dropdown"
                id={`${props.name}-country-code`}
                aria-expanded="false"
                disabled
            >
                <span className="me-2">🇪🇸</span>
            </button>
            <ul className="dropdown-menu"></ul>
            <input
                className={classNames(
                    'form-control',
                    {
                        'is-invalid': error,
                        'is-valid': !error && props.value.length > 0
                    },
                    stylesForm.input
                )}
                placeholder={props.placeholder}
                type="tel"
                name={props.name}
                autoComplete="tel-area-code"
                aria-describedby={`${props.name}-country-code`}
                id={props.name}
                value={props.value}
                onChange={handleOnChange}
                disabled={props.disabled}
                required={props.required}
            />
            <div className="invalid-feedback">{error || props.defaultValidationMessage}</div>
        </div>
    );
}

export default InputPhone;
