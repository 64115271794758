import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { InvoicePost, postInvoicesListThunk } from '@/store/invoices/postInvoicesList.slice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { companyNames } from '@/pages/Login/Login.controller';
export interface FileLoaded {
    id: number;
    data: string;
    fileName: string;
    products: boolean;
    multiInvoices: boolean;
}

export interface Metadata {
    name?: string;
}


const UploadFilesController = () => {
    const [files, setFiles] = useState<FileLoaded[]>([]);
    const dispatch = useDispatch();

    const onDrop = useCallback(
        (acceptedFiles) => {
            const handleFileChosen = async (file: any) => {
                return new Promise((resolve, reject) => {
                    let fileReader = new FileReader();
                    fileReader.onload = (e: any) => {
                        resolve({ data: e.target.result, name: file.name });
                    };
                    fileReader.onerror = reject;
                    fileReader.readAsDataURL(file);
                });
            };

            const readAllFiles = async (AllFiles: []) => {
                const newFiles: FileLoaded[] = [...files];
                const results = await Promise.all(
                    AllFiles.map(async (file) => {
                        const fileContents = await handleFileChosen(file);
                        return fileContents;
                    })
                );
                results.map((f: any) => {
                    newFiles.push({
                        id: newFiles.length,
                        data: f.data,
                        fileName: f.name,
                        products: false,
                        multiInvoices: false
                    });
                });
                setFiles(newFiles);
                return results;
            };

            readAllFiles(acceptedFiles);
        },
        [files]
    );

    const handleChangeCheckOptions = (
        file: FileLoaded,
        multiInvoices: boolean,
        products: boolean
    ) => {
        const newFiles = files.map((f) => {
            if (f.id === file.id) {
                return {
                    id: file.id,
                    data: file.data,
                    fileName: file.fileName,
                    products: products,
                    multiInvoices: multiInvoices
                };
            } else {
                return f;
            }
        });
        setFiles(newFiles);
    };

    const handlerRemoveCard = (id: number) => {
        const copyFiles = [...files];
        copyFiles.splice(id, 1);
        const newFiles = copyFiles.map((f, key) => {
            return { ...f, id: key };
        });
        setFiles(newFiles);
    };

    const handleSubmitInvoices = () => {
        const invoices: InvoicePost[] = files.map((f, key) => {
            const metadata = {
                name: f.fileName
            } as Metadata;

            return {
                metadata: metadata,
                type: f.multiInvoices ? 'recibida_batch' : 'recibida',
                image: f.data,
                products: f.products
            } as InvoicePost;
        });
        dispatch(postInvoicesListThunk({ invoices }));
        setFiles([]);
    };

    useEffect(() => {
        const subdomain = window.location.host.split('.')[0];
        const companyName = companyNames[subdomain] ?? companyNames["biyectiva"];
        if (companyName === "Biyectiva") {
            toast.success('La versión demo solo puede procesar facturas recibidas por tu CIF');
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    return {
        getRootProps,
        getInputProps,
        isDragActive,
        files,
        handleChangeCheckOptions,
        handleSubmitInvoices,
        handlerRemoveCard
    };
};

export default UploadFilesController;
