import React from 'react';
import styles from './AppLayout.module.scss';
import Loader from '@/components/Loader/Loader';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';

interface AppLayoutProps {
    children?: React.ReactNode;
}
const AppLayout = ({ children }: AppLayoutProps) => {
    const isLoading = useSelector((state: RootState) => state.loader.setLoader.isLoading);

    return (
        <div className={styles.container}>
            <Loader isLoading={isLoading} />
            {children}
        </div>
    );
};

export default AppLayout;
