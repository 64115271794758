import { Error } from '@/models/Error';
import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export interface Invoice {
    client: string;
    created_at: string;
    status: string;
    token: string;
    type: string;
    updated_at: string;
    metadata: string; // string porque viene stringifeado de Scooby
}

export interface GetInvoicesListState {
    isLoading: boolean;
    error: Error | null;
    invoices: Invoice[];
    current_page: number;
    max_pages: number;
}
export type GetInvoicesParams = {
    start?: string;
    end?: string;
    limit?: string;
    page?: string;
};

export const getInvoicesListThunk = createAsyncThunk<
    GetInvoicesListState,
    GetInvoicesParams,
    { rejectValue: Error }
>('invoices/getInvoicesList', async (params: GetInvoicesParams = {}, thunkApi) => {
    return request({
        url: `/client/invoices`,
        method: 'GET',
        params: new URLSearchParams(params),
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    })
        .then((res: ApiResponse<GetInvoicesListState>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            toast.error(err.data.code);
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getInvoicesListSlice = createSlice({
    name: 'invoices',
    initialState: {
        isLoading: false,
        error: null,
        invoices: [],
        current_page: 1,
        max_pages: 1
    } as GetInvoicesListState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInvoicesListThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getInvoicesListThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false),
                (state.error = null),
                (state.invoices = payload.invoices),
                (state.current_page = payload.current_page),
                (state.max_pages = payload.max_pages);
        });
    }
});
