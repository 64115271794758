import { FieldValidator } from "../Fields.types";

export enum InputTextAutoComplete {
    OFF = "off",
    NAME = "name",
}

export interface InputTextProps {
    value: string;
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    fieldValidators: FieldValidator<string>[];
    autoComplete: InputTextAutoComplete;
    defaultValidationMessage?: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    autoFocus?: boolean;
}