import classNames from 'classnames';
import { Link } from 'react-router-dom';
import successMessageStyles from './SuccessMessage.module.scss';

function SuccessMessage() {
    return (
        <div
            className={classNames(
                'd-table-cell row p-4 bg-white rounded-end',
                successMessageStyles['successMessage']
            )}
        >
            <h3 className={'mb-5 text-center'}>
                <strong>Registro</strong> completado.
            </h3>
            <div
                className={classNames(
                    'text-center text-success mb-3',
                    successMessageStyles['successMessage--illustration']
                )}
            >
                <i className="bi bi-check-circle"></i>
            </div>
            <div className="mb-5">
                <p className="text-center">
                    Antes de poder iniciar sesión un moderador debe aprobar tu cuenta.
                </p>
            </div>
            <Link to="/login" className="btn btn-success w-100">
                Volver al inicio de sesión
            </Link>
        </div>
    );
}

export default SuccessMessage;
