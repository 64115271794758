import { getInvoicesListThunk, GetInvoicesParams } from '@/store/invoices/getInvoicesList.slice';
import { RootState } from '@/store/rootReducer';
import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { loaderActions } from '@/store/loader';

const LIMIT = 20;

const ManageController = () => {
    const dispatch = useDispatch();
    const { invoices, current_page } = useSelector(
        (state: RootState) => state.invoicesList.invoicesList
    );

    const pageRef = useRef<number>(0);
    pageRef.current = current_page;

    const [initialDate, setInitialDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [invoiceTokenList, setInvoiceTokenList] = useState<string[]>([]);
    const [exportAllState, setExportAllState] = useState(false);
    const [onClickFilter, handleOnClickFilter] = useState(false);
    const [checkBoxList, setCheckBoxList] = useState<any>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [toggleResetState, setToggleResetState] = useState(false);

    const ACCES_TOKEN = accessToken.get();

    const handlerChangeInitialDate = (event: any) => {
        setInitialDate(event.target.value);
    };

    const handlerChangeEndDate = (event: any) => {
        setEndDate(event.target.value);
    };

    const handlerFilter = () => {
        dispatch(getInvoicesListThunk(getInvoicesByPage()));
        handleOnClickFilter(!onClickFilter);
    };
    const handleToggleResetState = () => {
        setToggleResetState(!toggleResetState);
    };
    const getInvoicesByPage = (page: number = 1) => {
        const params: GetInvoicesParams = {
            limit: LIMIT.toString(),
            page: page ? page.toString() : current_page.toString()
        };

        if (initialDate !== '') params['start'] = initialDate.replaceAll('-', '');
        if (endDate !== '') params['end'] = endDate.replaceAll('-', '');

        return params;
    };

    const handleInvoiceList = (token: string) => {
        if (invoiceTokenList.includes(token)) {
            const tokenIndex = invoiceTokenList.indexOf(token);
            invoiceTokenList.splice(tokenIndex, 1);
        } else {
            const aux = [...invoiceTokenList, token];
            setInvoiceTokenList(aux);
        }
    };

    const handleInvoiceListAll = (tokenAll: string[]) => {
        setInvoiceTokenList(tokenAll);
    };

    const handlerMultipleInvoiceExport = () => {
        if (invoiceTokenList.length > 0) {
            dispatch(loaderActions.toggleLoading());
            request({
                url: `/client/invoices/export`,
                method: 'POST',
                data: { invoices: invoiceTokenList },
                responseType: 'blob',
                extraHeaders: {
                    Authorization: `Bearer ${ACCES_TOKEN}`
                }
            })
                .then((res: ApiResponse) => {
                    const fileName = res.headers['content-disposition'].split('filename=')[1];

                    const blob = new Blob([res.data], { type: res.headers['content-type'] });
                    saveAs(blob, fileName);
                    //setExportAllState(false);
                    //setCheckBoxList([]);
                    //setCheckAll(false);

                    dispatch(loaderActions.toggleLoading());
                    handleToggleResetState();
                })
                .catch((err: ApiResponse<Error>) => {
                    toast.error(err.data);
                });
        }
    };

    useEffect(() => {
        dispatch(getInvoicesListThunk(getInvoicesByPage()));

        const interval = setInterval(() => {
            dispatch(getInvoicesListThunk(getInvoicesByPage(pageRef.current)));
        }, 30000);

        return () => clearInterval(interval);
    }, [onClickFilter]);

    useEffect(() => {
        if (invoiceTokenList.length > 0) {
            setExportAllState(true);
        } else {
            setExportAllState(false);
        }
    }, [invoiceTokenList, setInvoiceTokenList]);

    useEffect(() => {
        setExportAllState(false);
        handleInvoiceListAll([]);
        setCheckBoxList([]);
        setCheckAll(false);
    }, [toggleResetState]);

    useEffect(() => {}, [exportAllState, checkBoxList, checkAll]);
    return {
        invoices,
        initialDate,
        invoiceTokenList,
        exportAllState,
        setExportAllState,
        getInvoicesByPage,
        handlerChangeEndDate,
        handlerChangeInitialDate,
        handlerFilter,
        handlerMultipleInvoiceExport,
        handleInvoiceList,
        handleInvoiceListAll,
        checkBoxList,
        setCheckBoxList,
        checkAll,
        setCheckAll,
        handleToggleResetState
    };
};
export default ManageController;
