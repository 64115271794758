import { getInvoicesListThunk } from '@/store/invoices/getInvoicesList.slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';

interface PaginationControllerProps {
    getInvoicesByPage: (i: number) => {};
    handleToggleResetState: () => void;
}

const PaginationController = ({
    getInvoicesByPage,
    handleToggleResetState
}: PaginationControllerProps) => {
    const { current_page, max_pages } = useSelector(
        (state: RootState) => state.invoicesList.invoicesList
    );

    const [numbers, setNumbers] = useState<number[]>([]);
    const dispatch = useDispatch();

    const handleChangePage = (page: number) => {
        handleToggleResetState();
        dispatch(getInvoicesListThunk(getInvoicesByPage(page)));
    };

    useEffect(() => {
        const arrNumber = [];
        for (let i = 0; i < max_pages; i++) {
            arrNumber.push(i + 1);
        }
        setNumbers(arrNumber);
    }, [max_pages]);

    return { current_page, numbers, max_pages, handleChangePage };
};
export default PaginationController;
