import { companyNames } from '@/pages/Login/Login.controller';
import React from 'react';
import styles from './GuestUserLayout.module.scss';
interface GuestUserLayoutProps {
    children?: React.ReactNode;
}
const GuestUserLayout = ({ children }: GuestUserLayoutProps) => {
    const subdomain = window.location.host.split('.')[0];
    const companyName = companyNames[subdomain] ?? companyNames["biyectiva"];

    return (
        <div className={styles.bannerImage}>
            <div className={styles.layer}>
                {companyName === "Biyectiva" && (
                    <>
                        <div className={styles.biyectivaLogo}></div>
                        <div className={styles.charmLogo}></div>
                    </>
                )}
                {children}
            </div>
        </div>
    );
};

export default GuestUserLayout;
