interface StateParameters {
    style: string;
    text: string;
}
const INFORMATION_STATE_DICCIONARY: any = {
    processed: { style: 'bg-success', text: 'Procesada' },
    processing: { style: 'bg-info', text: 'Procesando' },
    validated: { style: 'bg-secondary', text: 'Validada' },
    downloaded: { style: 'bg-primary', text: 'Descargada' },
    error: { style: 'bg-danger', text: 'No procesada' },
    cleared: { style: 'bg-warning', text: 'Descartada' }
};
const STATE_DEFAULT: StateParameters = {
    style: 'bg-dark',
    text: 'Indefinido'
};
interface InformationStateProps {
    state: string;
}

const InformationState = ({ state }: InformationStateProps) => {
    const currentState = INFORMATION_STATE_DICCIONARY[state] || STATE_DEFAULT;
    return <span className={`badge ${currentState.style} fs-6`}> {currentState.text}</span>;
};

export default InformationState;
