interface ProgressBarProps {
    value: string;
    progress: number;
}
const ProgressBar = ({ value, progress }: ProgressBarProps) => {
    return (
        <div className="progress">
            <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow={value}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: `${progress}%` }}
            >
                {/* {progress}% */}
            </div>
        </div>
    );
};

export default ProgressBar;
