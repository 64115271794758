import { Invoice } from '@/store/invoices/getInvoicesList.slice';

export enum StateInvoice {
    DOWNLOADED = 'downloaded',
    PROCESSED = 'processed',
    PROCESSING = 'processing',
    VALIDATED = 'validated',
    ERROR = 'error',
    CLEARED = 'cleared'
}
export interface TableProps {
    data: Invoice[];
    handleInvoiceList: (i: string) => void;
    handleInvoiceListAll: (i: string[]) => void;
    setExportAllState: (i: boolean) => void;
    invoiceTokenList: string[];
    checkBoxList: [];
    setCheckBoxList: (i: any) => void;
    checkAll: boolean;
    setCheckAll: (i: boolean) => void;
}

export const CheckDisable: any = {
    downloaded: true,
    processed: true,
    processing: false,
    validated: true,
    error: false,
    cleared: false
};
