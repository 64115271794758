import { combineReducers } from "@reduxjs/toolkit";
import { getQuotaSlice } from "./getQuota.slice";
import { getUserSlice } from "./getUser.slice";
import { setPasswordSlice } from "./setPassword.slice";

export const usersReducer = combineReducers({
    getUser: getUserSlice.reducer,
    setPassword: setPasswordSlice.reducer,
    getQuota: getQuotaSlice.reducer,
});

export const usersActions = {
    ...getUserSlice.actions,
    ...setPasswordSlice.actions,
    ...getQuotaSlice.actions,
};