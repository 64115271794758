import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Frame, { useFrame } from 'react-frame-component';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { toast } from 'react-toastify';
import accessToken from '@/utils/helpers/accessToken.helper';

interface Props {
    token: string;
    apiKey: string;
}

const InnerComponent = ({ token, apiKey }: Props) => {
    // Hook returns iframe's window and document instances from Frame context
    const { document, } = useFrame();

    useEffect(() => {
        // we build a form to post login
        const form2 = document.createElement('form');
        form2.setAttribute('action', `https://devfacturas.biyectiva.com/login`);
        form2.setAttribute('method', 'POST');
        form2.setAttribute('target', 'my-iframe');

        // we add the api key as a hidden input
        const input2 = document.createElement('input');
        input2.setAttribute('type', 'hidden');
        input2.setAttribute('name', 'apikey');
        input2.setAttribute('value', apiKey);
        form2.appendChild(input2);

        // we submit the form
        document.body.appendChild(form2);
        form2.submit();

        // we build a form to post inside document
        const form = document.createElement('form');
        form.setAttribute('action', `https://devfacturas.biyectiva.com/facturas/${token}`);
        form.setAttribute('method', 'POST');
        form.setAttribute('target', 'my-iframe');

        // we add the api key as a hidden input
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', 'apikey');
        input.setAttribute('value', apiKey);
        form.appendChild(input);

        /* // we add the firstToken as a hidden input
        const inputToken = document.createElement('input');
        inputToken.setAttribute('type', 'hidden');
        inputToken.setAttribute('name', 'firstToken');
        inputToken.setAttribute('value', token);
        form.appendChild(inputToken);

        // we add an allTokens input with the token inside a list
        const inputAllTokens = document.createElement('input');
        inputAllTokens.setAttribute('type', 'hidden');
        inputAllTokens.setAttribute('name', 'allTokens');
        inputAllTokens.setAttribute('value', `["${token}"]`);
        form.appendChild(inputAllTokens); */

        // we submit the form
        document.body.appendChild(form);
        form.submit();
    }, []);

    return null;
};

const CheckInvoice = () => {
    const { token } = useParams();

    const ACCES_TOKEN = accessToken.get();

    const [apiKey, setApiKey] = useState<string>('');

    useEffect(() => {
        // we get the api key
        request({
            url: `/client/api_key`,
            method: 'GET',
            extraHeaders: {
                Authorization: `Bearer ${ACCES_TOKEN}`
            }
        })
            .then((res: ApiResponse) => {
                setApiKey(res.data.api_key);
            })
            .catch((err: ApiResponse<Error>) => {
                toast.error("Algo no ha ido bien. Por favor, recarga la página para poder seleccionar la opción de corregir los documentos.");
                setApiKey("AgJpjAJufKbchFMdTLlFu7bB5jJ2msmG");
            });
    }, []);

    return (
        <Frame name="my-iframe" width="100%" height="100%" >
            {apiKey != '' &&
                <InnerComponent token={token} apiKey={apiKey} />
            }
        </Frame>
    );
}

export default CheckInvoice;