import {
    DateDifferenceInDaysToToday,
    DateHoursMinutes,
    DateSplitBar
} from '@/utils/helpers/formatDate.helper';
import InformationState from './InformatonState/InformationState';
import RowOptions from './RowOptions/RowOptions';
import TableController from './Table.controller';
import { CheckDisable, StateInvoice, TableProps } from './Table.types';
import { Metadata } from '@/pages/Dashboard/sections/UploadFiles/UploadFiles.controller';

const Table = ({
    data,
    handleInvoiceList,
    handleInvoiceListAll,
    setExportAllState,
    invoiceTokenList,
    checkBoxList,
    setCheckBoxList,
    checkAll,
    setCheckAll
}: TableProps) => {
    const { handlerChangeCheckAll, handlerChangeCheckBoxList } = TableController({
        data,
        handleInvoiceList,
        handleInvoiceListAll,
        setExportAllState,
        invoiceTokenList,
        checkBoxList,
        setCheckBoxList,
        checkAll,
        setCheckAll
    });

    return (
        <table
            className={`table table-hover w-100 border rounded-3 m-0 text-center  align-middle table-responsive`}
            style={{ boxSizing: 'border-box' }}
        >
            <thead>
                <tr>
                    <th scope="col">
                        <input
                            type={'checkbox'}
                            onChange={handlerChangeCheckAll}
                            checked={checkAll}
                        />
                    </th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Fecha de subida</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Acciones</th>
                </tr>
            </thead>
            <tbody>
                {data &&
                    data.map((value, key) => {
                        const metadata = JSON.parse(value.metadata ?? '{}') as Metadata;

                        return (
                            <tr key={value.token}>
                                <th>
                                    <input
                                        id={value.token}
                                        type={'checkbox'}
                                        disabled={!CheckDisable[value.status]}
                                        onChange={handlerChangeCheckBoxList}
                                        checked={
                                            !CheckDisable[value.status]
                                                ? false
                                                : checkBoxList[value.token as any] === undefined
                                                ? false
                                                : checkBoxList[value.token as any]
                                        }
                                    />
                                </th>
                                <th>
                                    {metadata?.name ?? '<Sin nombre>'}
                                </th>
                                <th>
                                    {` ${DateSplitBar(value.created_at)} ${DateHoursMinutes(
                                        value.created_at
                                    )} `}
                                    <br />
                                    {'Hace ' + DateDifferenceInDaysToToday(value.created_at)}
                                </th>
                                <th>
                                    <InformationState state={value.status as StateInvoice} />
                                </th>
                                <th>
                                    <RowOptions
                                        invoiceName={metadata?.name ?? value.token}
                                        invoiceStatus={value.status as StateInvoice}
                                        tokenInvoice={value.token}
                                    />
                                </th>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};

export default Table;
