import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Error } from '@/models/Error';
import { User } from '@/models/User';
import accessToken from '@/utils/helpers/accessToken.helper';

export const getUser = createAsyncThunk<User, void, { rejectValue: Error }>(
    'users/getUser',
    async (params, thunkApi) => {
        return request({
            url: '/user',
            method: 'GET',
            extraHeaders: {
                Authorization: `Bearer ${accessToken.get()}`
            }
        } as ApiRequest<{ email: string; password: string }>)
            .then((res: ApiResponse<User>) => res.data)
            .catch((err: ApiResponse<Error>) => {
                return thunkApi.rejectWithValue(err.data);
            });
    }
);

type GetUserState = {
    loading: boolean;
    error: Error | null;
    user: User | null;
};

export const getUserSlice = createSlice({
    name: 'users',
    initialState: {
        user: null,
        loading: false,
        error: null
    } as GetUserState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.user = payload;
            state.error = null;
        });
        builder.addCase(getUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUser.rejected, (state, { payload }) => {
            state.loading = false;
            state.user = null;

            if (payload) {
                state.error = payload;
            }
        });
    }
});
