import { FieldValidator } from '@/components/forms/fields/Fields.types';
import { getUser } from '@/store/users/getUser.slice';
import { setPasswordThunk } from '@/store/users/setPassword.slice';
import { useEffect, useRef, useState } from 'react';
import Toast from 'bootstrap/js/dist/toast';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';
import { companyMails, companyNames } from '@/pages/Login/Login.controller';


function ProfileController() {
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [valid, setValid] = useState<boolean>(true);

    const subdomain = window.location.host.split('.')[0];
    const companyName = companyNames[subdomain] ?? companyNames["biyectiva"];
    const companyMail = companyMails[subdomain] ?? companyMails["biyectiva"];

    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state.users.getUser.user);

    const loading = useSelector((state: RootState) => state.users.getUser.loading);
    const error = useSelector((state: RootState) => state.users.getUser.error);

    const isChanged = useSelector((state: RootState) => state.users.setPassword.isChanged);
    const loadingSetPassword = useSelector((state: RootState) => state.users.setPassword.loading);
    const errorSetPassword = useSelector((state: RootState) => state.users.setPassword.error);

    const formRef = useRef<HTMLFormElement>(null);
    const toastRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        if (toastRef.current && (error || isChanged)) {
            const toast = new Toast(toastRef.current);

            toast.show();

            setPassword('');
            setConfirmPassword('');

            if (formRef.current) {
                formRef.current.reset();
            }
        }
    }, [error, isChanged]);

    const passwordValidators: FieldValidator<string>[] = [
        {
            callback: (value: string) => value.length > 0,
            message: 'El campo no puede estar vacío'
        },
        {
            callback: (value: string) => value.length >= 8,
            message: 'La contraseña debe tener al menos 8 caracteres'
        }
    ];

    const confirmPasswordValidators: FieldValidator<string>[] = [
        {
            callback: (value: string) => value.length > 0,
            message: 'El campo no puede estar vacío'
        },
        {
            callback: (value: string) => value === password,
            message: 'Las contraseñas no coinciden'
        }
    ];

    const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(true);

        setPassword(event.target.value);
    };

    const onChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValid(true);

        setConfirmPassword(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (formRef.current) {
            if (!formRef.current.checkValidity()) {
                event.stopPropagation();

                setValid(false);
            } else {
                dispatch(setPasswordThunk({ password }));
            }
        }
    };

    return {
        user,
        error,
        formRef,
        toastRef,
        valid,
        companyName,
        companyMail,
        loading,
        password,
        isChanged,
        confirmPassword,
        errorSetPassword,
        loadingSetPassword,
        passwordValidators,
        onChangePassword,
        handleSubmit,
        onChangeConfirmPassword,
        confirmPasswordValidators
    };
}

export default ProfileController;
