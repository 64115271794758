const MILLISECONDS_IN_DAY = 86400000;
const MILLISECONDS_IN_HOUR = 3600000;
const MILLISECONDS_IN_MINUTE = 60000;
const MILLISECONDS_IN_SECOND = 1000;

export const DateSplitBar = (date: string) => {
    let today = new Date(date);
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    const dateString = dd + '/' + mm + '/' + yyyy;
    return dateString;
};
export const DateHoursMinutes = (date: string) => {
    const dateObject = new Date(date);
    //FIXME debería ser "- offset"
    const dateWithTimezone = new Date(
        dateObject.getTime() + dateObject.getTimezoneOffset() * 60 * 1000
    );

    //FIXME debería ser dateWithTimezone
    return dateWithTimezone.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const DateDifferenceInDaysToToday = (startDateString: string) => {
    const dateObject = new Date(startDateString);
    //FIXME debería ser "- offset"
    const dateWithTimezone = new Date(
        dateObject.getTime() + dateObject.getTimezoneOffset() * 60 * 1000
    );

    const difference = new Date().getTime() - dateWithTimezone.getTime();

    // Días
    const daysDifference = Math.floor(difference / MILLISECONDS_IN_DAY);
    const remainder = difference % MILLISECONDS_IN_DAY;

    function daysOfCurrentMonthAndYear() {
        const fecha = new Date();
        return new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0).getDate();
    }

    const assignStringDays = (days: number) => {
        let totalDays = days;
        let stringDays = '';
        if (days >= daysOfCurrentMonthAndYear()) {
            const month = Math.floor(days / 30);
            stringDays += `${month} ${month === 1 ? 'mes' : 'meses'}`;
            totalDays = days - month * 30;
        }
        if (totalDays >= 7) {
            const weeks = Math.floor(totalDays / 7);
            totalDays = totalDays - weeks * 7;
            if (stringDays !== '') {
                if (totalDays > 0) {
                    stringDays += `, `;
                } else {
                    stringDays += ` y `;
                }
            }
            stringDays += ` ${weeks} ${weeks === 1 ? 'semana' : 'semanas'}`;

            if (totalDays > 0) {
                stringDays += ` y `;
            }
        }
        if (totalDays > 0) {
            stringDays += ` ${totalDays} ${totalDays === 1 ? 'día' : 'días'}`;
        }

        stringDays?.trim();
        return stringDays;
    };

    const assignStringTime = (milliseconds: number) => {
        // Horas
        const hours = Math.floor(milliseconds / MILLISECONDS_IN_HOUR);
        milliseconds = milliseconds % MILLISECONDS_IN_HOUR;

        // Minutos
        const minutes = Math.floor(milliseconds / MILLISECONDS_IN_MINUTE);
        milliseconds = milliseconds % MILLISECONDS_IN_MINUTE;

        // Segundos
        const seconds = Math.floor(milliseconds / MILLISECONDS_IN_SECOND);

        let stringTime = '';
        if (hours > 0) {
            stringTime += `${hours} ${hours === 1 ? 'hora' : 'horas'}`;
        }

        if (minutes > 0) {
            if (stringTime !== '') {
                stringTime += ` y `;
            }
            stringTime += `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`;
        } else if (hours <= 0) {
            stringTime += `${seconds} ${seconds === 1 ? 'segundo' : 'segundos'}`;
        }

        stringTime?.trim();
        return stringTime;
    };

    if (daysDifference > 0) {
        return assignStringDays(daysDifference);
    }

    return assignStringTime(remainder);
};

