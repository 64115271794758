import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { Location, useLocation } from 'react-router-dom';

function PublicRouteController() {
    const { state }: any = useLocation();

    const isAuthenticated = useSelector((state: RootState) => state.auth.login.isAuthenticated);

    return { state, isAuthenticated };
}

export default PublicRouteController;
