import PaginationController from './Pagination.controller';

export interface PaginationProps {
    getInvoicesByPage: (i: number) => {};
    handleToggleResetState: () => void;
}

const Pagination = ({ getInvoicesByPage, handleToggleResetState }: PaginationProps) => {
    const { current_page, numbers, max_pages, handleChangePage } = PaginationController({
        getInvoicesByPage,
        handleToggleResetState
    });

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end m-0">
                <li className="page-item">
                    <button
                        className="page-link"
                        aria-label="Previous"
                        onClick={() => handleChangePage(current_page - 1)}
                        disabled={current_page <= 1}
                    >
                        <span aria-hidden="true">&laquo;</span>
                    </button>
                </li>
                {numbers.map((i: number, key: number) => {
                    return (
                        <li className="page-item" key={key}>
                            <button
                                className="page-link"
                                onClick={() => {
                                    const val = key + 1;
                                    if (current_page.toString() === val.toString()) {
                                    } else {
                                        handleChangePage(i);
                                    }
                                }}
                            >
                                {i}
                            </button>
                        </li>
                    );
                })}
                <li className="page-item">
                    <button
                        className="page-link"
                        aria-label="Next"
                        onClick={() => handleChangePage(parseInt(current_page.toString()) + 1)}
                        disabled={current_page >= max_pages}
                    >
                        <span aria-hidden="true">&raquo;</span>
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
