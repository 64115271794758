import Sidebar from '@/components/Sidebar';
import AppLayout from '@/layout/AppLayout/AppLayout';
import classNames from 'classnames';
import { Navigate, Route, Routes } from 'react-router-dom';

import dashboardStyles from './Dashboard.module.scss';
import Manage from './sections/Manage/Manage';
import Profile from './sections/Profile/Profile';
import UploadFiles from './sections/UploadFiles/UploadFiles';
import CheckInvoice from '../CheckInvoice/CheckInvoice';

function Dashboard() {
    return (
        <AppLayout>
            <div className="vw-100 vh-100 d-flex">
                <Sidebar />
                <main
                    className={classNames(
                        ' w-100 h-100 bg-light bg-gradient align-items-center overflow-auto d-flex flex-column',
                        dashboardStyles.main
                    )}
                >
                    <Routes>
                        <Route path="/" element={<Navigate to={'/upload-files'} />} />
                        <Route path="/upload-files" element={<UploadFiles />} />
                        <Route path="/manage" element={<Manage />} />
                        <Route path="/check/:token" element={<CheckInvoice />} />
                        <Route path="/profile" element={<Profile />} />
                    </Routes>
                </main>
            </div>
        </AppLayout>
    );
}

export default Dashboard;
