import { RootState } from '@/store/rootReducer';
import { authActions } from '@/store/auth';
import accessToken from '@/utils/helpers/accessToken.helper';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuota } from '../../store/users/getQuota.slice';

function SidebarController() {
    const [isOpen, setIsOpen] = useState(false);
    const [quotaProgress, setQuotaProgress] = useState(0);
    const [quotaProgressString, setQuotaProgressString] = useState('');

    const dispatch = useDispatch();

    const toggleSidebar = () => setIsOpen(!isOpen);

    const user = useSelector((state: RootState) => state.users.getUser.user);
    const api_use = useSelector((state: RootState) => state.users.getQuota.api_use);
    const quota = useSelector((state: RootState) => state.users.getQuota.quota);

    const handleLogout = () => {
        dispatch(authActions.logout());

        // TODO: la idea es que se quite el token del localStorage en un middleware de redux
        accessToken.remove();
    };

    useEffect(() => {
        dispatch(getQuota());

        const interval = setInterval(() => dispatch(getQuota()), 30000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setQuotaProgress((quota ? (api_use ?? 0)/quota : 0) * 100);
        setQuotaProgressString(api_use + "/" + quota);
    }, [api_use, quota]);

    return {
        isOpen,
        toggleSidebar,
        handleLogout,
        user,
        quotaProgress,
        quotaProgressString
    };
}

export default SidebarController;
