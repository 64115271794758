import { Error } from '@/models/Error';
import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setPasswordThunk = createAsyncThunk<
    void,
    { password: string },
    { rejectValue: Error }
>('users/setPassword', async (params, thunkApi) => {
    return request({
        url: '/client',
        method: 'PATCH',
        data: params,
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest<{ password: string }>)
        .then((res: ApiResponse<void>) => res.data)
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

type SetPasswordState = {
    loading: boolean;
    error: Error | null;
    isChanged: boolean;
};

export const setPasswordSlice = createSlice({
    name: 'users',
    initialState: {
        isChanged: false,
        loading: false,
        error: null
    } as SetPasswordState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setPasswordThunk.fulfilled, (state) => {
            state.loading = false;
            state.isChanged = true;
            state.error = null;
        });
        builder.addCase(setPasswordThunk.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(setPasswordThunk.rejected, (state, { payload }) => {
            state.loading = false;
            state.isChanged = false;

            if (payload) {
                state.error = payload;
            }
        });
    }
});
