import { useState } from "react";

import { FieldValidator } from "../Fields.types";

function InputEmailController(onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    fieldValidators: FieldValidator<string>[]
) {
    const [error, setError] = useState<string>();

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;

        onChange(event);

        const isValid = fieldValidators.every(({ message, callback }) => {
            if (!callback(value)) {
                setError(message);
                return false;
            }

            return true;
        });

        if (isValid) {
            setError(undefined);
        }
    }

    return {
        error,
        handleOnChange
    };
}

export default InputEmailController;