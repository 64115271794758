import { Error } from '@/models/Error';
import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { getInvoicesListThunk } from '@/store/invoices/getInvoicesList.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export interface RowOptionsProps {
    invoiceName: string;
    invoiceStatus: string;
    tokenInvoice: string;
}

const RowOptionsController = ({ invoiceName, invoiceStatus, tokenInvoice }: RowOptionsProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const ACCES_TOKEN = accessToken.get();

    const handlerDownloadImgPdf = () => {
        request({
            url: `/client/invoices/${tokenInvoice}/download`,
            method: 'GET',
            responseType: 'blob',
            extraHeaders: {
                Authorization: `Bearer ${ACCES_TOKEN}`
            }
        })
            .then((res: ApiResponse) => {
                const fileName = invoiceName; // res.headers['content-disposition'].split('filename=')[1];

                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                saveAs(blob, fileName);
            })
            .catch((err: ApiResponse<Error>) => {
                toast.error(err.data.code);
            });
    };

    const handlerCorrection = () => {
        //FIXME we can make it work, but not now... FUUUUUUUUUUUUUUUUCK
        // navigate(`/view/${tokenInvoice}`);

        navigate(`/check/${tokenInvoice}`);
    };

    const handlerExport = () => {
        request({
            url: `/client/invoices/export`,
            method: 'POST',
            data: { invoices: [tokenInvoice] },
            responseType: 'blob',
            extraHeaders: {
                Authorization: `Bearer ${ACCES_TOKEN}`
            }
        })
            .then((res: ApiResponse) => {
                const fileName = res.headers['content-disposition'].split('filename=')[1];

                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                saveAs(blob, fileName);
            })
            .catch((err: ApiResponse<Error>) => {
                toast.error(err.data);
            });
    };

    const handlerValidate = () => {
        request({
            url: `/client/invoices/${tokenInvoice}`,
            method: 'PATCH',
            data: { status: 'validated' },
            extraHeaders: {
                Authorization: `Bearer ${ACCES_TOKEN}`
            }
        })
            .then((res: ApiResponse<{ status: string }>) => {
                toast.success('Factura validada');
                dispatch(getInvoicesListThunk({}));
            })
            .catch((err: ApiResponse<Error>) => {
                toast.error(err.data.code);
            });
    };

    const handlerDelete = () => {
        request({
            url: `/client/invoices/${tokenInvoice}`,
            method: 'DELETE',
            data: { status: invoiceStatus },
            extraHeaders: {
                Authorization: `Bearer ${ACCES_TOKEN}`
            }
        })
            .then((res: ApiResponse<{ status: string }>) => {
                toast.success('Factura eliminada');
                dispatch(getInvoicesListThunk({}));
            })
            .catch((err: ApiResponse<Error>) => {
                toast.error(err.data.code);
            });
    };

    return { handlerDownloadImgPdf, handlerExport, handlerCorrection, handlerValidate, handlerDelete };
};
export default RowOptionsController;
