import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Error } from '@/models/Error';
import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { toast } from 'react-toastify';
import accessToken from '@/utils/helpers/accessToken.helper';
import { Metadata } from '@/pages/Dashboard/sections/UploadFiles/UploadFiles.controller';

export interface PostInvoicesList {}

export interface InvoicePost {
    metadata: Metadata;
    type: 'recibida' | 'recibida_batch';
    image: string;
    'max-pages'?: number;
    client: string;
    products: boolean;
}

export const postInvoicesListThunk = createAsyncThunk<
    { token: string },
    { invoices: InvoicePost[] },
    { rejectValue: Error }
>('/invoices/postInvoicesList', async (params, thunkApi) => {
    return request({
        url: '/client/invoices',
        method: 'POST',
        data: params,
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest<{ invoices: InvoicePost[] }>)
        .then((res: ApiResponse<{ token: string }>) => {
            toast.success('Se ha enviado con éxito');
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            toast.error(err.data.code);
            return thunkApi.rejectWithValue(err.data);
        });
});

export interface PostInvoicesListState {
    isLoading: boolean;
    error: Error | null;
}

export const postInvoicesListSlice = createSlice({
    name: 'invoices',
    initialState: { isLoading: false, error: null } as PostInvoicesListState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postInvoicesListThunk.fulfilled, (state) => {
            (state.isLoading = false), (state.error = null);
        });
        builder.addCase(postInvoicesListThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(postInvoicesListThunk.rejected, (state, { payload }) => {
            state.isLoading = false;
            if (payload) {
                state.error = payload;
            }
        });
    }
});
