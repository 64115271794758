import classNames from 'classnames';
import ProfileController from './Profile.controller';

function Profile() {
    const {
        user,
        error,
        loading,
        companyName,
        companyMail,
        password,
        formRef,
        toastRef,
        confirmPassword,
        isChanged,
        loadingSetPassword,
        errorSetPassword,
        valid,
        onChangeConfirmPassword,
        onChangePassword,
        passwordValidators,
        handleSubmit,
        confirmPasswordValidators
    } = ProfileController();

    return (
        <>
            <div className="w-100 p-4 row">
                <div className="col-12">
                    <div className="card h-100">
                        <div className="card-header">Información del usuario</div>
                        <div className="card-body">
                            {loading ? (
                                <div className="d-flex justify-content-center h-100 align-items-center">
                                    <div className="spinner-border text-secondary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : error ? (
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            ) : (
                                user && (
                                    <form
                                        className={classNames('bg-white rounded-end w-100')}
                                        noValidate
                                    >
                                        <div className="row">
                                            <div className="mb-3 col-6">
                                                <label htmlFor="name" className="form-label">
                                                    Nombre de la empresa
                                                </label>
                                                <input
                                                    type={'text'}
                                                    name="name"
                                                    id="name"
                                                    className="form-control "
                                                    readOnly
                                                    disabled
                                                    value={user.name}
                                                ></input>
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="email" className="form-label">
                                                    Correo electrónico
                                                </label>
                                                <input
                                                    type={'email'}
                                                    name="email"
                                                    id="email"
                                                    className="form-control"
                                                    readOnly
                                                    disabled
                                                    value={user.email}
                                                ></input>
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="cif" className="form-label">
                                                    CIF
                                                </label>
                                                <input
                                                    type={'text'}
                                                    name="cif"
                                                    id="cif"
                                                    className="form-control"
                                                    readOnly
                                                    disabled
                                                    value={user.cif}
                                                ></input>
                                            </div>
                                            <div className="mb-3 col-6">
                                                <label htmlFor="phone" className="form-label">
                                                    Teléfono
                                                </label>
                                                <input
                                                    type={'phone'}
                                                    name="phone"
                                                    id="phone"
                                                    className="form-control"
                                                    readOnly
                                                    disabled
                                                    value={user.phone}
                                                ></input>
                                            </div>
                                            <div className="mb-3 col-12">
                                                <label htmlFor="api-key" className="form-label">
                                                    Api Key
                                                </label>
                                                <input
                                                    // type={'password'}
                                                    name="api-key"
                                                    id="api-key"
                                                    className="form-control"
                                                    readOnly
                                                    disabled
                                                    value={user.api_key}
                                                ></input>
                                            </div>
                                        </div>
                                    </form>
                                )
                            )}
                        </div>
                    </div>
                </div>
                {/* 
                <div className="col-4">
                    <div className="row h-100">
                        <div className="col-12 h-100">
                            <div className="card h-100">
                                <div className="card-header">Ajustes del usuario</div>
                                <div className="card-body">
                                    <div className="row">
                                        <form
                                            className={classNames(
                                                'd-table-cell bg-white rounded-end col-12',
                                                {
                                                    'was-validated': !valid
                                                }
                                            )}
                                            noValidate
                                            ref={formRef}
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <label
                                                        htmlFor="name"
                                                        className="form-label mb-3"
                                                    >
                                                        Actualizar contraseña
                                                    </label>
                                                    <div className="mb-3">
                                                        <InputPassword
                                                            name="new-password"
                                                            placeholder="Nueva contraseña"
                                                            value={password}
                                                            onChange={onChangePassword}
                                                            defaultValidationMessage="Introduzca una contraseña válida"
                                                            fieldValidators={passwordValidators}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <InputPassword
                                                            name="confirm-new-password"
                                                            placeholder="Repetir nueva contraseña"
                                                            value={confirmPassword}
                                                            defaultValidationMessage="Repita la contraseña"
                                                            onChange={onChangeConfirmPassword}
                                                            fieldValidators={
                                                                confirmPasswordValidators
                                                            }
                                                            required
                                                        />
                                                    </div>
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            <InputSubmit
                                                                disabled={error ? true : false}
                                                                loading={loadingSetPassword}
                                                                value={'Confirmar'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                */}
                <div className="col-12 pt-4">
                    <div className="card">
                        <div className="card-header">Soporte</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <p>
                                        El servicio de soporte de {companyName + ' '}
                                        resolverá cualquier cuestión o problema que experimente en la aplicación.
                                        Si ese es el caso, puede contactar con el soporte de la aplicación
                                        haciendo uso del siguiente correo:
                                        <a className="ms-1" href={`mailto:${companyMail}`}>
                                            {companyMail}
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        Además, puede hacer uso de la página de documentación
                                        pulsando en el siguiente
                                        {companyName == "Biyectiva" ? (
                                            <a
                                                className="ms-1"
                                                href="https://www.biyectiva.com/docs/#/"
                                                target={'_blank'}
                                            >
                                                enlace
                                            </a>
                                        ):
                                        companyName == "Byprox" ? (
                                            <a
                                                className="ms-1"
                                                href="http://documentacion.byprox.com/"
                                                target={'_blank'}
                                            >
                                                enlace
                                            </a>
                                        ): 
                                        (
                                            <a
                                                className="ms-1"
                                                href="https://documentacion.facturainteligente.es/#/"
                                                target={'_blank'}
                                            >
                                                enlace
                                            </a>
                                        )
                                        }
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 11 }}>
                <div
                    id="liveToast"
                    className={classNames(
                        'toast align-items-center text-white bg-primary border-0 text-white',
                        { 'bg-danger': !isChanged && errorSetPassword, 'bg-success': isChanged }
                    )}
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    ref={toastRef}
                >
                    <div className="toast-body d-flex align-items-center">
                        <i
                            className={classNames('bi flex-shrink-0 me-2 fs-4', {
                                'bi-exclamation-circle': !isChanged && errorSetPassword,
                                'bi-check-circle': isChanged
                            })}
                        ></i>
                        <div>
                            {isChanged
                                ? 'Contraseña actualizada correctamente'
                                : errorSetPassword && errorSetPassword.code}
                        </div>
                        <button
                            type="button"
                            className="btn-close btn-close-white me-2 m-auto"
                            data-bs-dismiss="toast"
                            aria-label="Close"
                        ></button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;
