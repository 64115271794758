import SignUpController from './SignUp.controller';
import Form from './sections/Form/Form';
import SuccessMessage from './sections/SuccessMessage/SuccessMessage';

function SignUp() {
    const { registered } = SignUpController();

    return <div>{!registered ? <Form /> : <SuccessMessage />}</div>;
}

export default SignUp;
