import classNames from 'classnames';
import InputEmail from '@/components/forms/fields/InputEmail';
import InputPassword from '@/components/forms/fields/InputPassword';
import InputSubmit from '@/components/forms/InputSubmit';
import LoginController from './Login.controller';
import loginStyles from './Login.module.scss';
import { Link } from 'react-router-dom';
import { InputSubmitType } from '@/components/forms/InputSubmit/InputSubmit';

function Login() {
    const {
        handleSubmit,
        formRef,
        valid,
        email,
        loading,
        password,
        companyLogo,
        companyName,
        emailValidators,
        passwordValidators,
        onChangeEmail,
        onChangePassword
    } = LoginController();

    return (
        <section className={loginStyles.login}>
            <form
                className={classNames(loginStyles.containerForm, {
                    'was-validated': !valid
                })}
                onSubmit={handleSubmit}
                ref={formRef}
                noValidate
            >
                <div className={loginStyles['login--form--illustration']}>
                    <img src={companyLogo} style={{ maxWidth: '400px' }} alt="" />
                </div>

                <div className="mb-3">
                    <InputEmail
                        name="email"
                        value={email}
                        onChange={onChangeEmail}
                        placeholder="Email"
                        fieldValidators={emailValidators}
                        defaultValidationMessage="Introduzca un email válido"
                        companyName={companyName}
                        required
                    />
                </div>
                <div className="mb-4">
                    <InputPassword
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        placeholder="Contraseña"
                        fieldValidators={passwordValidators}
                        defaultValidationMessage="Introduzca una contraseña válida"
                        companyName={companyName}
                        required
                    />
                </div>
                <div className={loginStyles.buttonSubmit}>
                    <InputSubmit
                        value="Iniciar sesión"
                        loading={loading}
                        type={InputSubmitType.LOGIN}
                        companyName={companyName}
                    />
                </div>
                {companyName === 'Biyectiva' && (
                    <>
                        <Link
                            className={classNames('text-center d-block', loginStyles['login--form--link'])}
                            aria-current="page"
                            to="/sign-up"
                        >
                            ¿No tienes cuenta?
                            <br />
                            Crea una nueva pulsando este enlace.
                        </Link>
                        <a
                            className={classNames('text-center d-block', loginStyles['login--form--link'])}
                            href="https://youtu.be/0s1_9OLRyjQ"
                            target="_blank"
                        >
                            Tutorial de uso
                        </a>
                    </>
                )}
            </form>
        </section>
    );
}

export default Login;
