import { FileLoaded } from '../UploadFiles';
import CloseIcon from '@/assets/icons/x-circle-fill.svg';
import styles from './CardFile.module.scss';
interface CardFileProps {
    file: FileLoaded;
    handleChangeCheckOptions: (file: FileLoaded, multiInvoices: boolean, products: boolean) => void;
    handlerRemoveCard: (id: number) => void;
}
const CardFile = ({ file, handleChangeCheckOptions, handlerRemoveCard }: CardFileProps) => {
    const handleCheckedMulti = () => {
        handleChangeCheckOptions(file, !file.multiInvoices, file.products);
    };
    const handleCheckedProduct = () => {
        handleChangeCheckOptions(file, file.multiInvoices, !file.products);
    };
    return (
        <div className={styles.container}>
            <div className={styles.iconContainer}>
                <CloseIcon className={styles.icon} onClick={() => handlerRemoveCard(file.id)} />
            </div>
            <div className={styles.fileName}>{file.fileName}</div>{' '}
            <div className={styles.checkboxContainer}>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={file.multiInvoices}
                        onChange={handleCheckedMulti}
                        id={`checkUploadItemOp1-${file.id}`}
                    />
                    <label
                        className="form-check-label  fw-lighter"
                        htmlFor={`checkUploadItemOp1-${file.id}`}
                    >
                        PDF con múltiples facturas
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={file.products}
                        onChange={handleCheckedProduct}
                        id={`checkUploadItemOp2-${file.id}`}
                    />
                    <label
                        className="form-check-label  fw-lighter"
                        htmlFor={`checkUploadItemOp2-${file.id}`}
                    >
                        Detectar líneas de producto
                    </label>
                </div>
            </div>
        </div>
    );
};

export default CardFile;
