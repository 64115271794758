import { login } from './auth/login.slice';
import { signUp } from './auth/signUp.slice';
import { postInvoicesListThunk } from './invoices/postInvoicesList.slice';
import { getUser } from './users/getUser.slice';
import { setPasswordThunk } from './users/setPassword.slice';

const rootThunkData = [
    login,
    signUp,
    //getInvoicesListThunk,
    postInvoicesListThunk,
    getUser,
    //getQuota,
    setPasswordThunk
];

const rootThunkHandler = (data: any[]) => {
    const newJ = { data: {} };

    data.map((e) => {
        newJ.data = {
            ...newJ.data,
            [e.pending as string]: (state: { isLoading: boolean }) => {
                state.isLoading = true;
            },
            [e.fulfilled as string]: (state: { isLoading: boolean }) => {
                state.isLoading = false;
            },
            [e.rejected as string]: (state: { isLoading: boolean }) => {
                state.isLoading = false;
            }
        };
    });

    return newJ.data;
};

export const rootThunk = rootThunkHandler(rootThunkData);
