import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';

function SignUpController() {
    const registered = useSelector((state: RootState) => state.auth.signUp.registered);

    return {
        registered
    };
}

export default SignUpController;
