import CardFile from './ CardFile/CardFile';
import UploadFilesController from './UploadFiles.controller';
import styles from './UploadFiles.module.scss';

function UploadFiles() {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        files,
        handleChangeCheckOptions,
        handleSubmitInvoices,
        handlerRemoveCard
    } = UploadFilesController();
    return (
        <div className={styles.container}>
            <div
                {...getRootProps()}
                className={`d-flex flex-column w-100 h-100 justify-content-center align-items-center ${styles.containerDropZone}`}
            >
                <input {...getInputProps()} multiple />
                {isDragActive ? (
                    <div className="d-flex flex-column  justify-content-center align-items-center">
                        <i className="bi bi-cloud-upload" style={{ fontSize: '10rem' }}></i>
                        <p className="text-center fw-lighter fs-2 mb-5">¡Suelta el archivo !</p>
                    </div>
                ) : (
                    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                        <i className="bi bi-cloud-upload" style={{ fontSize: '10rem' }}></i>
                        <p className="text-center fw-lighter fs-2 mb-5">
                            ¡Procese sus <strong>documentos</strong> en la nube!
                        </p>
                        <button className="btn btn-primary">
                            <i className="bi bi-upload me-2"></i>
                            Subir archivos
                        </button>
                    </div>
                )}
            </div>
            {files.length > 0 && (
                <>
                    <div className={styles.containerCards}>
                        {files.map((file, key) => {
                            return (
                                <CardFile
                                    file={file}
                                    key={key}
                                    handleChangeCheckOptions={handleChangeCheckOptions}
                                    handlerRemoveCard={handlerRemoveCard}
                                />
                            );
                        })}
                    </div>
                    <div className={styles.containerButton}>
                        <button className={styles.buttonSend} onClick={handleSubmitInvoices}>
                            Enviar
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default UploadFiles;
