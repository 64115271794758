import Pagination from '@/components/pagination/Pagination';
import Table from '@/components/table/Table';
import ManageController from './Manage.controller';
import styles from './Manage.module.scss';

function Manage() {
    const {
        invoices,
        initialDate,
        exportAllState,
        invoiceTokenList,
        setExportAllState,
        getInvoicesByPage,
        handlerChangeEndDate,
        handlerChangeInitialDate,
        handlerFilter,
        handlerMultipleInvoiceExport,
        handleInvoiceList,
        handleInvoiceListAll,
        setCheckBoxList,
        checkBoxList,
        checkAll,
        setCheckAll,
        handleToggleResetState
    } = ManageController();

    return (
        <div className="w-100 h-100 p-4 row">
            <div className="col-12">
                <div className="card h-100">
                    <div className="card-header d-flex justify-content-between align-items-center bg-white">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handlerMultipleInvoiceExport}
                            disabled={!exportAllState}
                        >
                            <i className="bi bi-file-earmark-arrow-down me-2"></i>Exportar
                            documentos seleccionados
                        </button>
                        <div className="input-group w-auto">
                            <input
                                type="date"
                                className="form-control"
                                placeholder="Desde"
                                aria-label="Desde"
                                onChange={handlerChangeInitialDate}
                                value={initialDate}
                            />
                            <span className="input-group-text">-</span>
                            <input
                                type="date"
                                className="form-control"
                                placeholder="Hasta"
                                aria-label="Hasta"
                                min={initialDate}
                                onChange={handlerChangeEndDate}
                            />
                            <button
                                type="submit"
                                className="btn btn-primary"
                                value="Filtrar"
                                onClick={handlerFilter}
                            >
                                <i className="bi bi-filter me-2"></i>
                                Filtrar
                            </button>
                        </div>
                    </div>
                    <div className="card-body p-0 h-100">
                        <Table
                            data={invoices}
                            handleInvoiceList={handleInvoiceList}
                            handleInvoiceListAll={handleInvoiceListAll}
                            setExportAllState={setExportAllState}
                            invoiceTokenList={invoiceTokenList}
                            checkBoxList={checkBoxList}
                            setCheckBoxList={setCheckBoxList}
                            checkAll={checkAll}
                            setCheckAll={setCheckAll}
                        />
                    </div>
                    <div className={'card-footer bg-white d-flex align-items-center'}>
                        {/*<p className="text-muted me-auto mb-0 fst-italic">
                            Mostrados 7 de 20 elementos
                        </p>*/}
                        <div className={styles.paginationContainer}>
                            <Pagination
                                getInvoicesByPage={getInvoicesByPage}
                                handleToggleResetState={handleToggleResetState}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Manage;
