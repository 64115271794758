import ReactDOM from 'react-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import App from '@/App';
import rootReducer from './store/rootReducer';
import React from 'react';
// import { makeServer } from './server';
import './styles/index.scss';

const store = configureStore({ reducer: rootReducer });

/* if (process.env.NODE_ENV === 'development') {
    makeServer({ environment: 'development' });
} */

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('app')
);
